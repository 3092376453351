import React, { Component, } from 'react';
import axios from "axios";
import { Redirect, NavLink, Link } from "react-router-dom";
import { Navbar } from 'react-bootstrap';
// import { Navbar, NavDropdown } from 'react-bootstrap';
import './Menu.scss'

class Menu extends Component{
    constructor(props){
        super(props);
        this.api = window.$domain;
        this.state = {
            user : '',
            avatar : '',
            color_aleatorio : '',
            reports: true,
            help: true,
            catalogs: true,
            security: true,
            kardex: true,
            filterReport: true,
            filterOficios:true,
            showMenu : true,
            showSubMenu: false,
            banderaSolicitud: true
        };
    }

    componentDidMount(){
        
        axios.get(`${this.api}/user`).then((response) => {
            let avatarLetters;
            let reports, help, catalogs, security, kardex;
            if(!localStorage.getItem('user')){
                localStorage.setItem('user', JSON.stringify(response.data));
                avatarLetters = this.createAvatar(response.data.name);
                localStorage.setItem('avatarLetters', avatarLetters);
            }

            response.data.config.map((permission) => {
                switch (permission.fk_permission) {
                    case 1:
                        reports = permission.active === 1 ? true : false;
                        break;                        
                    case 2:
                        help = permission.active === 1 ? true : false;
                        break;                        
                    case 3:
                        catalogs = permission.active === 1 ? true : false;
                        break;
                    case 4:
                        security = permission.active === 1 ? true : false;
                        break;
                    case 8:
                        kardex = permission.active === 1 ? true : false;
                        break;
                    default:
                        break;
                }
                return true;
            });

            let banderaSolicitud = true;
            this.setState({
                avatar: localStorage.getItem('avatarLetters'),
                user : response.data.name,
                role: response.data.fk_role,
                reports: reports,
                help: help,
                catalogs: catalogs,
                security: security,
                kardex: kardex,
                banderaSolicitud:banderaSolicitud
            });
        }).catch((error) => {
            if((error.response && error.response.status === 401) || !error.status){
                localStorage.removeItem("isLoggedIn");
                localStorage.removeItem('user');
                window.location = '/';
            }
        });
        if(!localStorage.getItem('avatar')){
            this.createColor();
        } else {
            this.setState({
                color_aleatorio: localStorage.getItem('avatar')
            });
        }
    }

    componentWillUnmount(){
    }

    createAvatar(name){
        var avatar = name;
        var avatarSplit = avatar.split(" ");
        var avatarLetters = "";
        if(avatarSplit.length > 1){
            avatarSplit.forEach(element => {
                avatarLetters += element.substr(0,1);
            });
            avatarLetters = avatarLetters.substr(0,2); //
        }else{
            avatarLetters = avatarSplit[0].substr(0,2);
        }
        return avatarLetters.toUpperCase();
    }

    aleatorio(inferior, superior){
        let numPosibilidades = superior - inferior
        let aleat = Math.random() * numPosibilidades
        aleat = Math.floor(aleat)
        return parseInt(inferior) + aleat
    }

    toggleSubMenu = () => {
        this.setState(prevState => ({
            showSubMenu: !prevState.showSubMenu
        }));
    }

    createColor(){
        let hexadecimal = ["0","1","2","3","4","5","6","7","8","9","A","B","C","D","E","F"];
        let color_aleatorio = "#";
        for(var i = 0; i < 6; i++){
            let posarray = this.aleatorio(0,hexadecimal.length)
            color_aleatorio += hexadecimal[posarray]
            this.setState({
                color_aleatorio: color_aleatorio
            });
            localStorage.setItem('avatar', color_aleatorio);
        }
    }

    onCloseSession(){
        let api = window.$domain;
        axios.get(`${api}/logout`)
        .then((response) => {
            localStorage.clear();
            window.location = '/';
        })
        .catch((error) => {
            if(error.response.status === 401){
                localStorage.clear();
                window.location = '/';
            }
        });
    }

    render(){
        const login = localStorage.getItem("isLoggedIn");
        const role = JSON.parse(localStorage.getItem('user')) || {};
        if(!login){
            return <Redirect to="/" />;
        }

        let navLink;
        if(role.fk_role === 1){
            navLink = (
                <Navbar bg="light" expand="lg">
                    {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    </button> */}
                    
                    <Navbar.Collapse className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            {/* <NavLink to="/formats" activeClassName="activeLink" className="formats">Formatos de evaluación</NavLink> */}
                            <NavLink to="/projects" activeClassName="activeLink" className="projects">Proyectos</NavLink>
                            <ul>
                                <li>
                                <i class='fas fa-list-ul'></i>
                                    <ul className="navbar-nav" type="circle"> <NavLink to="/pre_ppis" activeClassName="activeLink" className="">Pre-Solicitudes PPI´s</NavLink> </ul>
                                </li>
                                
                                {/*<li>
                                <i class='fas fa-list-ul'></i>
                                    <ul className="navbar-nav" type="circle"> <NavLink to="/Oficios" activeClassName="activeLink" className="">Aprobados</NavLink> </ul>
                                </li>*/}
                            </ul>
                            
                            { this.state.reports && <NavLink to="/reports/charts" activeClassName="activeLink" className="charts">Análisis y Reportes</NavLink>}
                            { this.state.help && <NavLink to="/help" activeClassName="activeLink" className="help">Ayuda / Contacto</NavLink> }
                            { this.state.catalogs && <NavLink to="/catalogs" activeClassName="activeLink" className="catalogs">Catálogos</NavLink> }
                            { this.state.security && <NavLink to="/security" activeClassName="activeLink" className="security">Seguridad</NavLink> }
                            { this.state.kardex && <NavLink to="/kardex" activeClassName="activeLink" className="kardex">Historial de PPI</NavLink> }
                            <div>
                {this.state.filterReport && (
                    <div>
                        <NavLink
                            to="#"
                            activeClassName="activeLink"
                            className="reportsFilters"
                            onClick={this.toggleSubMenu}
                        >
                            Reportes
                        </NavLink>
                        {this.state.showSubMenu && (
                            <ul>
                                <li>
                                    <NavLink to="/reportsFilters" activeClassName="activeLink" className="reportsFilters">
                                        Reportes PPI's
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/ReportsOficios" activeClassName="activeLink" className="">
                                        PPI's aprobados
                                    </NavLink>
                                </li>
                            </ul>
                        )}
                    </div>
                )}
            </div>

                            {/* { this.state.filterReport && <NavLink to="/reportsFilters" activeClassName="activeLink" className="reportsFilters">Reportes PPI</NavLink> }
                            <ul>
                                <li>
                                <i class='fas fa-list-ul'></i>
                                    <ul className="navbar-nav" type="circle"> <NavLink to="/ReportsOficios" activeClassName="activeLink" className="">PPI's aprobados</NavLink> </ul>
                                </li>
                                
                            </ul> */}
                           
                            
                        </ul>
                    </Navbar.Collapse>
                </Navbar>
            );
        }else if(role.fk_role === 2){
            navLink = (                
                <Navbar bg="light" expand="lg">
                    {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    </button> */}
                    
                    <Navbar.Collapse className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            <NavLink to="/ppis" activeClassName="activeLink" className="myppis">{this.state.banderaSolicitud ? "Mis PPI's":"Mis Pre-PPI's"}</NavLink>

                            <ul>
                                <li>
                                <i class='fas fa-list-ul'></i>
                                    <ul className="navbar-nav" type="circle"> <NavLink to="/pre_ppis" activeClassName="activeLink" className="">Pre-Solicitudes PPI´s</NavLink> </ul>
                                </li>
                            </ul>

                            <NavLink to="/help" activeClassName="activeLink" className="help">Ayuda / Contacto</NavLink>
                        </ul>
                    </Navbar.Collapse>
                </Navbar>
            );
        }

        return (
            <div className="Menu col-lg-2">
                <Link to="/home"><img className="pt-2 img-fluid" src="/images/logo-black.png" alt="" height="100" /></Link>
                <div className="row user-header">
                    <div className="avatar" style={{backgroundColor: this.state.color_aleatorio}}>{this.state.avatar}</div>
                    <label>{this.state.user}</label>
                    <div className="icon material-icons size-18 text-grey float-right">expand_more</div>
                </div>
                {navLink}
                <div className="logout">
                    <button className="btn btn-primary-custom" onClick={this.onCloseSession}>Cerrar Sesión</button>
                </div>
            </div>
        );
    }

}
export default Menu;

